import $ from 'jquery';
//import jQuery from 'jquery';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/dropdown';

import './scss/app.scss';

window.$ = $;

$(document).ready(function(){


function myFunction(x) {
  if (x.matches) {

	$('.navbar .dropdown').hover(function() {
	$(this).find('.dropdown-menu').first().stop(true, true).slideDown(1);

	}, function() {
	$(this).find('.dropdown-menu').first().stop(true, true).slideUp(1);

	});

	$('.navbar .dropdown > a').click(function(){
	location.href = this.href;
	});
	
  }


}

var x = window.matchMedia("(min-width: 1199px)")
myFunction(x) // Call listener function at run time
x.addListener(myFunction) // Attach listener function on state changes 






	$('#mec_skin_192 .mec-event-article .col-md-9').addClass('event-info').removeClass('col-sm-9').removeClass('col-md-9');
});